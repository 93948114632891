<template>
  <div class="tw-bg-white tw-m-8 tw-rounded-lg tw-border-2 tw-border-mp-purple tw-shadow-xl">
    <div
        v-if="showRelative"
        class="tw-rounded-t-lg tw-bg-mp-green tw-text-xl tw-w-full tw-h-12 tw-text-white tw-flex tw-justify-center tw-items-center"
    >
      Most Popular
    </div>

    <div class="tw-mt-2 tw-p-4">
      <div class="tw-flex tw-justify-between tw-items-center tw-mb-2">
        <div
            class="tw-bg-gold tw-text-white tw-bg-gradient-to-r tw-from-mp-green tw-to-blue-500  tw-px-3 tw-py-1 tw-rounded-lg tw-text-sm tw-font-semibold">
          MyPet Premium
        </div>
        <div
            class="tw-bg-mp-purple tw-text-white  tw-px-3 tw-py-1 tw-rounded-lg tw-text-sm tw-font-semibold">
          Free 7-Day Trial
        </div>
      </div>
      <div class="tw-text-md mt-1 tw-font-light">
        <span :class="{'tw-line-through': discountedPrice > 0}">£12 Yearly</span>
        <span v-if="discountedPrice > 0"> £{{ discountedPrice }} Yearly</span>
      </div>
      <div class="tw-text-left">
        <div class="tw-ml-8 tw-mt-6 tw-flex">
          <CheckCircleIcon class="tw-text-mp-green tw-flex-shrink-0 tw-h-6 tw-w-6"></CheckCircleIcon>
          <div class="tw-ml-2">Current and future pets registered for life</div>
        </div>
        <div class="tw-ml-8 tw-mt-3 tw-flex">
          <CheckCircleIcon class="tw-text-mp-green tw-flex-shrink-0 tw-h-6 tw-w-6"></CheckCircleIcon>
          <div class="tw-ml-2">Update your details for life</div>
        </div>
        <div class="tw-ml-8 tw-mt-3 tw-flex">
          <CheckCircleIcon class="tw-text-mp-green tw-flex-shrink-0 tw-h-6 tw-w-6"></CheckCircleIcon>
          <div class="tw-ml-2">6 monthly reminders</div>
        </div>
        <div class="tw-ml-8 tw-mt-3 tw-flex">
          <CheckCircleIcon class="tw-text-mp-green tw-flex-shrink-0 tw-h-6 tw-w-6"></CheckCircleIcon>
          <div class="tw-ml-2">Basic registration certificate</div>
        </div>
        <div class="tw-ml-8 tw-mt-3 tw-flex">
          <CheckCircleIcon class="tw-text-mp-green tw-flex-shrink-0 tw-h-6 tw-w-6"></CheckCircleIcon>
          <div class="tw-ml-2">Access to MyPet perks</div>
        </div>
        <div class="tw-ml-8 tw-mt-3 tw-flex">
          <CheckCircleIcon class="tw-text-mp-green tw-flex-shrink-0 tw-h-6 tw-w-6"></CheckCircleIcon>
          <div class="tw-ml-2">Add additional contacts</div>
        </div>
        <div class="tw-ml-8 tw-mt-3 tw-flex">
          <CheckCircleIcon class="tw-text-mp-green tw-flex-shrink-0 tw-h-6 tw-w-6"></CheckCircleIcon>
          <div class="tw-ml-2">Add vet details</div>
        </div>
        <div class="tw-ml-8 tw-mt-3 tw-flex">
          <CheckCircleIcon class="tw-text-mp-green tw-flex-shrink-0 tw-h-6 tw-w-6"></CheckCircleIcon>
          <div class="tw-ml-2">Microchip searched notifications</div>
        </div>
        <div class="tw-ml-8 tw-mt-3 tw-flex">
          <CheckCircleIcon class="tw-text-mp-green tw-flex-shrink-0 tw-h-6 tw-w-6"></CheckCircleIcon>
          <div class="tw-ml-2">Anytime certificate download</div>
        </div>
        <div class="tw-ml-8 tw-mt-3 tw-flex">
          <CheckCircleIcon class="tw-text-mp-green tw-flex-shrink-0 tw-h-6 tw-w-6"></CheckCircleIcon>
          <div class="tw-ml-2">Upload a picture of your pet</div>
        </div>
        <div class="tw-ml-8 tw-mt-3 tw-flex">
          <CheckCircleIcon class="tw-text-mp-green tw-flex-shrink-0 tw-h-6 tw-w-6"></CheckCircleIcon>
          <div class="tw-ml-2">Covers all your pets</div>
        </div>
        <div class="tw-ml-8 tw-mt-3 tw-mb-8 tw-flex">
          <CheckCircleIcon class="tw-text-mp-green tw-flex-shrink-0 tw-h-6 tw-w-6"></CheckCircleIcon>
          <div class="tw-ml-2">Premium Support</div>
        </div>
        <div
            v-if="isLoggedIn && subscriptionType === 'basic' && showRelative"
            class="tw-flex tw-justify-center tw-items-center tw-mt-4 tw-mb-4"
        >
          <div
              class="tw-text-center tw-border tw-rounded tw-bg-mp-purple tw-text-white tw-p-2 tw-cursor-pointer tw-w-1/3 tw-border-black"
              @click="createSubscription"
          >
            Upgrade
          </div>
        </div>
        <div
            v-if="isLoggedIn && subscriptionType === 'premium' && showRelative"
            class="tw-flex tw-justify-center tw-items-center tw-mt-8 tw-mb-8 tw-bg-white tw-rounded tw-ml-8 tw-mr-8 tw-py-1 tw-text-mp-blue"
        >
          <InformationCircleIcon class="tw-mr-1 tw-h-8"></InformationCircleIcon>
          <div>You are currently on this plan</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {CheckCircleIcon, InformationCircleIcon} from "@vue-hero-icons/solid";
import Stripe from "@/models/Stripe";

const runCheckout = async function (result) {
  const stripe = window.Stripe(process.env.VUE_APP_STRIPE_KEY);

  stripe.redirectToCheckout({sessionId: result.id});
};

export default {
  name: "MyPetPremiumSubscriptionPanel",
  components: {InformationCircleIcon, CheckCircleIcon},
  props: {
    showRelative: true,
    discountedPrice: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      user: null,
      subscriptionType: "basic",
    };
  },
  async mounted() {
    this.user = this.$store.getters.user;
    this.subscriptionType = await this.getSubscriptionType();
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
  },
  methods: {
    async createSubscription() {
      if (this.subscriptionType === "basic") {
        this.$mixpanel.track("Button Clicked", {
          button_name: "Subscription Upgrade",
          version: 'a',
          date: '20240805',
        });
        const result = await Stripe.createSubscription();
        await runCheckout(result.data);
      }
    },
    //if user is signed up then update payment
    //if user is not signed up then create subscription
    async updatePaymentMethod() {
      if (this.subscriptionType === "basic") {
        await this.createSubscription();
      } else {
        const result = await Stripe.updatePaymentMethod();
        window.location = result.url;
      }
    },
  },
};
</script>

<style scoped>
</style>