import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import vuetify from "./plugins/vuetify";
import "./index.css";
import Mixpanel from "./mixpanel";
import User from "@/models/User";

Vue.prototype.$http = axios;

Vue.config.productionTip = false;

Vue.use(Mixpanel);

router.afterEach((to, from) => {
    Vue.prototype.$mixpanel.track("Page View", {
        page: to.name,
    });
});

window.Http = require("./services/Http").default;
window.Form = require("./services/Form").default;

const token = localStorage.getItem("token");
if (token) {
    window.Http.setAuthorizationToken(token);
}

Vue.mixin({
    methods: {
        async retrieveUser() {
            const user = await User.retrieve();

            if (user.duties && user.duties.length > 0) {
                switch (user.duties[0]) {
                    case "confirm_transfer_details":
                        break;
                }
            }

            return user;
        },
        async getSubscription() {
            return JSON.parse(localStorage.getItem("subscription"));
        },
        async getSubscriptionType() {
            return JSON.parse(localStorage.getItem("subscription"))?.plan ?? "basic";
        },
        async getFreeChipQueue() {
            return JSON.parse(localStorage.getItem("freeChipQueue")) || [];
        },
        async saveToFreeChipQueue(pet) {
            var freeChipQueue =
                JSON.parse(localStorage.getItem("freeChipQueue")) || [];

            freeChipQueue.push({name: pet.name});

            localStorage.setItem("freeChipQueue", JSON.stringify(freeChipQueue));
        },
        async saveSubscription(subscription) {
            localStorage.setItem("subscription", JSON.stringify(subscription));
        },
        async onSubscribed() {
            localStorage.setItem("subscription", "premium");
        },
        async registerMixpanel(user) {
            this.$mixpanel.identify(user.id);

            this.$mixpanel.people.set({
                $email: user.email,
                $last_login: new Date(),
            });
        },
        isValidEmail(email) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        },
    },
});

new Vue({
    render: (h) => h(App),
    mode: "history",
    router,
    store,
    vuetify,
}).$mount("#app");