<template>
  <div>
    <div v-if="petNames && petNames.length > 0" class="">
      <div class="tw-text-mp-blue tw-text-lg">Pets</div>
      <div
          class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-mt-4"
      >
        <div
            class="tw-bg-mp-blue-opacity tw-w-2/3 tw-rounded tw-border-2 tw-border-mp-blue"
        >
          <div v-for="petName in petNames" :key="petName" class="">
            <div class="tw-mt-1 tw-mb-1">{{ petName }}</div>
          </div>
        </div>
      </div>
    </div>
    <div
        class="tw-flex tw-justify-between tw-items-center md:tw-ml-40 md:tw-mr-40 tw-mt-8"
    >
      <div>
        <div class="tw-text-mp-dark-blue tw-font-semibold tw-text-lg tw-mb-2">
          MyPet Basic
        </div>
        <div
            class="tw-rounded tw-border-2 tw-py-4 tw-px-4 tw-border-mp-blue tw-cursor-pointer tw-w-44 tw-h-24 tw-mt-4 tw-shadow-2xl"
            :class="[
            premiumSelected ? 'tw-bg-white' : 'tw-bg-mp-blue tw-text-white',
          ]"
            @click="selectBasic"
        >
          {{ this.orderTotal > 0 ? "£" + this.orderTotal : "Included" }}
        </div>
      </div>
      <div>
        <div class="tw-text-mp-dark-blue tw-font-semibold tw-text-lg tw-mb-2">
          MyPet Premium
        </div>
        <div
            @click="selectPremium"
            class="tw-relative tw-rounded tw-border-2 tw-py-4 tw-px-4 tw-border-mp-blue tw-cursor-pointer tw-w-44 tw-h-24 tw-mt-4 tw-shadow-2xl"
            :class="[
            premiumSelected ? 'tw-bg-mp-blue tw-text-white' : 'tw-bg-white',
          ]"
        >
          <div class="tw-font-semibold">Annual</div>
          <div class="tw-font-semibold">              <span v-if="couponApplied">
              <s>£12</s> £{{ this.discountedPrice }}
            </span>
            <span v-else>
          £{{ this.premiumPrice }}
        </span></div>
          <div class="tw-absolute tw-top-20 tw-left-12">
            <span
                class="tw-inline-block tw-text-white tw-px-2 tw-py-1 tw-rounded-full tw-bg-mp-green tw-shadow-2xl"
            >Popular</span
            >
          </div>
        </div>
      </div>
    </div>
    <div
        class="tw-flex tw-flex-col tw-w-full tw-mt-8 tw-justify-center tw-items-center"
    >
      <div class="md:tw-w-2/3 sm:tw-w-full">
        <my-pet-basic-subscription-panel
            v-if="!premiumSelected"
            offer-page
            @acceptBasic="acceptBasic"
            :show-relative="false"
        ></my-pet-basic-subscription-panel>
      </div>
      <div class="md:tw-w-2/3 sm:tw-w-full">
        <my-pet-premium-subscription-panel
            v-if="premiumSelected"
            offer-page
            @acceptPremium="acceptPremium"
            :show-relative="false"
        ></my-pet-premium-subscription-panel>
      </div>
    </div>
    <div class="tw-flex tw-justify-center tw-items-center tw-mt-8 tw-mb-8">
      <div
          :class="{
          'tw-text-center tw-border tw-rounded tw-bg-mp-purple tw-text-white tw-p-2 tw-cursor-pointer tw-w-1/4 tw-border-black':
            !continueClicked,
          'tw-text-center tw-border-2 tw-rounded tw-bg-white tw-text-mp-purple tw-p-2 tw-cursor-pointer tw-w-1/4 tw-border-mp-purple':
            continueClicked,
        }"
          @click="continueRegistration"
      >
        Continue
      </div>
    </div>
  </div>
</template>

<script>
import {CheckCircleIcon} from "@vue-hero-icons/solid";
import MyPetBasicSubscriptionPanel from "@/components/shared/MyPetBasicSubscriptionPanel.vue";
import MyPetPremiumSubscriptionPanel from "@/components/shared/MyPetPremiumSubscriptionPanel.vue";
import StripeModel from "@/models/Stripe";

const runCheckout = async function (result) {
  const stripe = window.Stripe(process.env.VUE_APP_STRIPE_KEY);
  stripe.redirectToCheckout({sessionId: result.id});
};
export default {
  props: {
    pets: Array,
  },
  components: {
    MyPetPremiumSubscriptionPanel,
    MyPetBasicSubscriptionPanel,
    CheckCircleIcon,
  },
  data() {
    return {
      subscriptionType: "basic",
      premiumSelected: true,
      continueClicked: false,
      couponApplied: false,
      premiumPrice: 12,
      discountedPrice: 12,
      user: null,
      petNames: [],
    };
  },
  async mounted() {
    this.$mixpanel.track(
        "Subscription checkout shown.",
        {
          version: 'a',
          date: '20240805',
        }
    );
    this.subscriptionType = await this.getSubscriptionType();
    this.user = await this.retrieveUser();
    this.petNames = this.user?.microchip_queue?.pets?.map((pet) => pet.name);
  },
  async beforeMount() {
    if (this.$route.query.coupon) {
      const result = await StripeModel.checkValidCoupon({coupon: this.$route.query.coupon});

      if (result.coupon) {
        this.couponApplied = true;
        this.discountedPrice = 1200 * result.coupon.value / 10000;
      }
    }
  },
  methods: {
    async acceptBasic() {
      if (this.orderTotal > 0) {
        await this.$router.push({name: "Checkout"});
      } else {
        await this.$router.push({
          name: "CheckoutSuccessFreeVersion",
          params: {pets: this.pets},
        });
      }
    },
    async acceptPremium() {
      const result = await StripeModel.createSubscription(this.$route.query.coupon);
      await runCheckout(result.data);
    },
    async selectPremium() {
      this.premiumSelected = true;
    },
    async selectBasic() {
      this.premiumSelected = false;
    },
    async continueRegistration() {
      this.continueClicked = true;
      if (this.premiumSelected) {
        await this.acceptPremium();
      } else {
        await this.acceptBasic();
      }
    },
  },
  computed: {
    orderTotal() {
      if (
          this.user &&
          this.user.microchip_queue &&
          this.user.microchip_queue.pet_count > 0
      ) {
        let value = this.user.microchip_queue?.pet_count * 2.99;
        return parseFloat(value.toFixed(2));
      }
    },
  },
};
</script>
<style scoped></style>